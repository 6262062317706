export function formatDate(thistime, fmt = 'yyyy-MM-dd') {
	let $this = new Date(thistime)
	let o = {
		'M+': $this.getMonth() + 1,
		'd+': $this.getDate(),
		'h+': $this.getHours(),
		'm+': $this.getMinutes(),
		's+': $this.getSeconds(),
		'q+': Math.floor(($this.getMonth() + 3) / 3),
		'S': $this.getMilliseconds()
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
		}
	}
	return fmt
}
// 验证为数字并且最多保留小数点后两位 
export function isTwoDecimal(num) {
	const reg = /^\d+(\.\d{0,2})?$/; //限制小数后两位
	return reg.test(num)
}
//验证是否为正整数  
export function isNumber(num) {
	const reg = /^[0-9]\d*$/; //有0的整数
	return reg.test(num)
}

//解决做乘法运算出现精度丢失
export function accMul(arg1, arg2) { 
	var m = 0,
			s1 = arg1.toString(),
			s2 = arg2.toString();
	try {
			m += s1.split(".")[1].length
	} catch (e) {}
	try {
			m += s2.split(".")[1].length
	} catch (e) {}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}