import axios from '@/utils/request'

// 商户列表（下拉筛选）//默认查了全部数据
export function merchantsDrop(params) {
  return axios({
    url: `/manager/common/merchants/dropdown`,
    method: 'get',
		params
  })
}
// 商户列表（下拉筛选）//搜索查询
export function merchantsDropTwo(params) {
  return axios({
    url: `/manager/common/merchants/dropdown1`,
    method: 'get',
		params
  })
}

//代理商列表（下拉筛选）
/**
 * @param {Object} params
 */
export function agentsDrop(params) {
  return axios({
    url: `/manager/common/agents/dropdown`,
    method: 'get',
		params
  })
}
//支付产品列表（下拉筛选）
export function payproductsDrop(params) {
  return axios({
    url: `/manager/common/payproducts/dropdown`,
    method: 'get',
		params
  })
}
//支付机构列表（下拉筛选）
export function organizationsDrop(params) {
  return axios({
    url: `/manager/common/organizations/dropdown`,
    method: 'get',
		params
  })
}
//支付通道列表（下拉筛选）
export function paychannelsDrop(params) {
  return axios({
    url: `/manager/common/paychannels/dropdown`,
    method: 'get',
		params
  })
}
//小程序列表（下拉筛选）
export function appsDrop(params) {
  return axios({
    url: `/manager/common/apps/dropdown`,
    method: 'get',
		params
  })
}

//已认证小程序列表（下拉筛选）
export function verifiedDrop() {
  return axios({
    url: `/manager/common/apps/v2/dropdown`,
    method: 'get',
  })
}

//三方列表（下拉筛选）
export function thirdsDrop(params) {
  return axios({
    url: `/manager/common/thirds/dropdown`,
    method: 'get',
		params
  })
}
// 短信验证码
export function validatecode(params) {
  return axios({
    url: `/manager/common/validatecode`,
    method: 'get',
		params
  })
}
// 获取地址
export function getAddress(){
  return axios({
    url: `/manager/common/city/dropdown`,
    method: 'get'
  })
}
// 分账商户列表（下拉筛选）
export function DivisionDrop(params) {
  return axios({
    url: `/manager/common/allocMch/dropdown`,
    method: 'get',
		params
  })
}

// 商户名称/店铺名称（下拉筛选）
export function MerchantShopDrop(params) {
  return axios({
    url: `/manager/common/merchantsAndStore/dropdown`,
    method: 'get',
		params
  })
}