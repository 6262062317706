import axios from '@/utils/request'

// 商户管理-渠道列表（下拉筛选）
export function mchchannelsDrop(mchId) {
  return axios({
    url: `/manager/account/mchchannels/dropdown?mchId=${mchId}`,
    method: 'get',
  })
}
// 商户管理-支付产品/支付通道（下拉筛选）
export function payDrop(data) {
  return axios({
    url: `/manager/account/payproductsandchannels/dropdown?orgId=${data.orgId}&mchChannelId=${data.mchChannelId}`,
    method: 'get',
  })
}
// 商户管理-代付产品/代付通道（下拉筛选）
export function agnpayDrop(data) {
  return axios({
    url: `/manager/account/agnpayproductsandchannels/dropdown?orgId=${data.orgId}&mchChannelId=${data.mchChannelId}`,
    method: 'get',
  })
}
// 商户管理-分账产品/分账通道（下拉筛选）
export function allDrop(data) {
  return axios({
    url: `/manager/account/allocproductsandchannels/dropdown?orgId=${data.orgId}&mchChannelId=${data.mchChannelId}`,
    method: 'get',
  })
}
// 商户列表
export function shopLists(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/merchants`,
    method: 'get',
    params
  })
}
// 新增商户
export function addShop(data) {
  return axios({
    url: `/manager/account/merchants`,
    method: 'post',
    data
  })
}
// 商户详情
export function shopDetail(id) {
  return axios({
    url: `/manager/account/merchants/${id}`,
    method: 'get'
  })
}
// 审核商户
export function auditShop(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/check`,
    method: 'put',
    data
  })
}
// 激活/冻结商户
export function setShopStatus(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/activate`,
    method: 'put',
    data
  })
}
// 编辑商户
export function setShop(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}`,
    method: 'put',
    data
  })
}
// 编辑结算账户
export function setOverAccount(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/settlements/${data.settlementId}`,
    method: 'post',
    data
  })
}
// 删除结算账户
export function delOverAccount(id, delId) {
  return axios({
    url: `/manager/account/merchants/${id}/settlements/${delId}`,
    method: 'delete'
  })
}
// 新增/修改渠道信息
export function setPlaceInfo(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${data.mchChannelId}`,
    method: 'put',
    data
  })
}
// 修改渠道状态
export function setPlaceSatus(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${data.mchChannelId}/status`,
    method: 'put',
    data
  })
}
// 删除渠道信息
export function delPlaceInfo(id, delId) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${delId}`,
    method: 'delete'
  })
}
// 获取渠道信息
export function getPlaceInfo(id, mchChannelId) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}`,
    method: 'get'
  })
}
// 新增/编辑支付产品
export function setPay(id, mchChannelId, productId,data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/payproducts/${productId}`,
    method: 'post',
    data
  })
}

// 启动/关闭支付产品
export function setPayStatus(id,mchChannelId,data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/payproducts/${data.productId}/status`,
    method: 'put',
    data
  })
}
// 启动关闭ip制导
export function setIpStatus(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/channels/${data.mchChannelId}/payproducts/${data.productId}/ipStatus`,
    method: 'put',
    data
  })
}
// 启动关闭 支付产品贸易模式
export function setProductTradeStatus(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/channels/${data.mchChannelId}/payproducts/${data.productId}/tradeStatus`,
    method: 'put',
    data
  })
}
// 启动关闭 支付产品-智能路由
export function setRouteStatusUrl(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/channels/${data.mchChannelId}/payproducts/${data.productId}/routeStatus`,
    method: 'put',
    data
  })
}

// 新增/编辑 - 智能路由配置
export function setPayIntell(id, mchChannelId, productId,data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/payproducts/${productId}/route`,
    method: 'post',
    data
  })
}
// 删除 - 智能路由配置
export function delPayIntell(mchId, mchChannelId,productId,data) {
  return axios({
    url: `/manager/account/merchants/${mchId}/channels/${mchChannelId}/payproducts/${productId}/route`,
    method: 'delete',
    data
  })
}

// 智能路由配置 - 列表
export function getRouteconfigs(params) {
  return axios({
    url: `/manager/route/config/merchants/routeconfigs`,
    method: 'get',
    params
  })
}
// 智能路由配置 - 列表 - 新增
export function setRouteconfig(mchId, data) {
  return axios({
    url: `/manager/route/config/merchants/${mchId}/routeconfig`,
    method: 'post',
		data
  })
}


// 智能路由配置 - 列表 - 重置状态
export function intellResettingStatus(mchId, routeId ,data) {
  return axios({
    url: `/manager/route/config/merchants/${mchId}/routeconfig/${routeId}/tradeStatus`,
    method: 'put',
    data
  })
}

// 智能路由配置 - 列表 - 冻结
export function intellFreezeStatus(mchId, routeId,data) {
  return axios({
    url: `/manager/route/config/merchants/${mchId}/routeconfig/${routeId}/status`,
    method: 'put',
    data,
  })
}









// 删除支付产品
export function delPay(id, mchChannelId, delId) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/payproducts/${delId}`,
    method: 'delete'
  })
}
// 新增/编辑代付产品
export function setUserPay(id, mchChannelId,oldpayType, data) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/agnpayproducts/${oldpayType}`,
    method: 'post',
    data
  })
}
// 启动/关闭代付产品
export function setUserPayStatus(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/channels/${data.mchChannelId}/agnpayproducts/${data.payType}/status`,
    method: 'put',
    data
  })
}
// 删除代付产品
export function delUserPay(id, mchChannelId, payType) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/agnpayproducts/${payType}`,
    method: 'delete'
  })
}
// 新增/编辑分账产品
export function setSplitPay(id, mchChannelId,settleType, data) {  
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/allocproducts/${settleType}`,
    method: 'post',
    data
  })
}
// 启动/关闭分账产品
export function setSplitPayStatus(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/channels/${data.mchChannelId}/allocproducts/${data.settleType}/status`,
    method: 'put',
    data
  })
}
// 删除分账产品
export function delSplitPay(id, mchChannelId, settleType) {
  return axios({
    url: `/manager/account/merchants/${id}/channels/${mchChannelId}/allocproducts/${settleType}`,
    method: 'delete'
  })
}
// 产品信息列表
export function getProducts(id) {
  return axios({
    url: `/manager/account/merchants/${id}/products`,
    method: 'get'
  })
}
// 获取配置信息
export function getConfig(id) {
  return axios({
    url: `/manager/account/merchants/${id}/config`,
    method: 'get'
  })
}
// 修改其他配置
export function setOtherConfig(id, data) {
  return axios({
    url: `/manager/account/merchants/${id}/otherconfig`,
    method: 'put',
    data
  })
}
// 新增微信APP
export function addWechartApp(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/apps/${smallId}`,
    method: 'post'
  })
}
// 删除微信APP
export function delWechartApp(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/apps/${smallId}`,
    method: 'delete'
  })
}
// 修改微信APP状态
export function setWechartApp(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/apps/${data.smallId}/status`,
    method: 'put',
    data
  })
}

//新增微信扫码+配置
export function addScanCode(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/native/${smallId}`,
    method: 'post'
  })
}

//删除微信扫码+ 配置
export function delScanCode(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/native/${smallId}`,
    method: 'delete'
  })
}

// 修改微信扫码+配置状态
export function setScanCode(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/native/${data.smallId}/status`,
    method: 'put',
    data
  })
}


// 新增收银APP
export function addSaleApp(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/cashierconfig/${smallId}`,
    method: 'post'
  })
}
// 删除收银APP
export function delSaleApp(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/cashierconfig/${smallId}`,
    method: 'delete'
  })
}
// 修改收银APP状态
export function setSaleApp(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/cashierconfig/${data.smallId}/status`,
    method: 'put',
    data
  })
}
//  商户管理-重发邮件
export function resend(mchId) {
  return axios({
    url: `/manager/account/merchants/${mchId}/email/resend`,
    method: 'post'
  })
}
//  商户管理-修改通用配置
export function setCommonconfig(mchId, data) {
  return axios({
    url: `/manager/account/merchants/${mchId}/commonconfig`,
    method: 'put',
    data
  })
}
//  商户管理-修改权限配置
export function setAuthconfig(mchId, data) {
  return axios({
    url: `/manager/account/merchants/${mchId}/authconfig`,
    method: 'put',
    data
  })
}
//  商户管理-修改权限配置-贸易模式开关
export function setTradeStatus(mchId, data) {
  return axios({
    url: `/manager/account/merchants/${mchId}/tradeStatus`,
    method: 'put',
    data
  })
}
// 新增/修改限流配置
export function addLimits(mchId, data, id = 0) {
  return axios({
    url: data.id ? `/manager/account/merchants/${mchId}/limits/${data.id}` : `/manager/account/merchants/${mchId}/limits`,
    method: 'post',
    data
  })
}
// 删除限流配置
export function delLimits(mchId, id) {
  return axios({
    url: `/manager/account/merchants/${mchId}/limits/${id}`,
    method: 'delete'
  })
}

// 新增H5收银台
export function addSaleH5(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/h5/${smallId}`,
    method: 'post'
  })
}
// 删除收银APP
export function delSaleH5(id, smallId) {
  return axios({
    url: `/manager/account/merchants/${id}/h5/${smallId}`,
    method: 'delete'
  })
}
// 修改收银APP状态
export function setSaleH5(data) {
  return axios({
    url: `/manager/account/merchants/${data.mchId}/h5/${data.smallId}/status`,
    method: 'put',
    data
  })
}

// 删除公众号配置 
export function delPublicConfig(id) {
  return axios({
    url: `/manager/account/mpCongif/${id}/`,
    method: 'delete'
  })
}

// 新增公众号配置 
export function addPublic(mchId,data) {
  return axios({
    url: `/manager/account/mpCongif/add/${mchId}`,
    method: 'post',
    data
  })
}


// 获取终端信息
export function getTermList(id) {
  return axios({
    url: `/manager/account/treminals/${id}`,
    method: 'get'
  })
}

//  店铺下拉列表
export function storesList(mchId) {
  return axios({
    url: `/manager/account/stores/`+mchId+`/list`,
    method: 'get',
  })
}
//  分账商户下拉列表
export function getMchList(mchId) {
  return axios({
    url: `/manager/account/allocMch/`+mchId+`/list`,
    method: 'get',
  })
}

//  终端状态开关
export function changeStatus(opTermNo) {
  return axios({
    url: `/manager/account/treminal/`+opTermNo,
    method: 'get',
  })
}

//  删除终端
export function delTerminal(opTermNo) {
  return axios({
    url: `/manager/account/treminal/`+opTermNo,
    method: 'delete',
  })
}
//  新增或编辑终端
export function addOrEditTerminal(mchId,data) {
  return axios({
    url: `/manager/account/treminal/add/`+mchId,
    method: 'post',
    data
  })
}

// 电子账簿商户列表
export function ledgerLists(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/trade/merchants`,
    method: 'get',
    params
  })
}
// 电子账簿商户列表-新增
export function addledger(data) {
  return axios({
    url: `/manager/account/trade/merchants`,
    method: 'post',
    data
  })
}
// 电子账簿商户-详情
export function ledgerDetail(id) {
  return axios({
    url: `/manager/account/trade/merchants/${id}`,
    method: 'get'
  })
}

//  电子账簿商户--重发邮件
export function ledgerResend(mchId) {
  return axios({
    url: `/manager/account/trade/merchants/${mchId}/email/resend`,
    method: 'post'
  })
}

// 电子账簿商户--进件 
export function mchApply(mchId, data) {
  return axios({
    url: `/manager/account/trade/merchants/${mchId}/apply`,
    method: 'put',
    data
  })
}

// 电子账簿商户--同步 type 1审核状态 2签约状态
export function mchApplyQuery(applyId,params) {
  return axios({
    url: `/manager/account/trade/merchants/${applyId}/applyQuery`,
    method: 'get',
    params
  })
}

// 电子账簿商户--签约    get
export function mchApplySign(applyId) {
  return axios({
    url: `/manager/account/trade/merchants/${applyId}/sign`,
    method: 'get'
  })
}
// 电子账簿商户 - 详情 - 编辑
export function setledger(id, data) {
  return axios({
    url: `/manager/account/trade/merchants/${id}`,
    method: 'put',
    data
  })
}

//电子账簿商户-电子账簿-开户 
export function merchantCreate(mchId) {
  return axios({
    url: `/manager/account/trade/merchants/${mchId}/merchantCreate`,
    method: 'get'
  })
}
//电子账簿商户-电子账簿-同步 
export function merchantCreateQuery(applyId) {
  return axios({
    url: `/manager/account/trade/merchants/${applyId}/merchantCreateQuery`,
    method: 'get'
  })
}

//电子账簿商户-补充材料-编辑 
export function putsupplementEidt(mchId,data) {
	return axios({
		url: `/manager/account/trade/merchants/supplement/${mchId}`,
		method: 'put',
		data
	})
}

// 查询可绑定的分账方
export function searAlloc(mchName,params) {
  return axios({
    url: `/manager/account/trade/merchants/${mchName}/Alloc`,
    method: 'get',
		params
  })
}
